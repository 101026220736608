<template lang="pug">
  .section.left-tab#career
    h3.title(:class="active ? 'active' : ''") Career
    p.content Recruiting talented personnels to join us on technological ventures
    .career-container
      .career-left
        .career-tab(
          v-for="(c, key) in career_list"
          :key="key"
          :class="selected == key ? 'active' : ''"
          @click="select(key)"
        )
          .title {{ c.name }}
      .career-right
        .career-card(
          v-for="(c, key) in career_list"
          :key="key"
          :class="selected == key ? 'active' : ''"
          @click="select(key)"
        )
          .title Scopes
          ul
            li(v-for="s in c.scope") {{ s }}
          .title Requirements
          ul
            li(v-for="r in c.requirement") {{ r }}
</template>

<script>
export default {
  data() {
    return {
      selected: 0,
      career_list: [
        {
          name: "Junior Software Developer",
          scope: [
            "Be a part of the core team of in-house project developments.",
            "Learn and apply full stack development, spanning from server configuration to frontend development.",
            "Hone your programming skills in the market's latest technology stack languages, ie Javascript, MySQL and more.",
            "Support, maintain and document software functionality.",
            "Involvement in software QA testing.",
          ],
          requirement: [
            "Advanced diploma in Computer Science/Information Technology or equivalent.",
            "Have basic knowledge in relational database design and implementation.",
            "Ability to work independently and in a team with effective presentation, strong analytical, interpersonal, documentation and communication skills.",
            "Enthusiastic learner, disciplined and responsible.",
            "Knowledge in Linux OS, full stack development, or any other notable achievement in software development will be a plus.",
          ],
        },
        {
          name: "Account Manager",
          scope: [
            "Identify and develop key accounts with business located within Malaysia.",
            "Develop strategic account plans to promote the core solutions offered by the company and adoption of these solutions.",
            "Develop customer relationships at a senior-level, which will include key operations.",
            "Work closely with management team to support business development activities.",
            "Drive revenue growth across multiple product platforms.",
            "Provide general sales support (needs analysis, data review, and product demonstrations).",
            "Prepare periodic forecasts and progress updates toward sales goals.",
            "Provide and coordinate technical training as required for new software launches and existing software support.",
            "Work closely with customer service team to ensure customers’ product requirements and delivery lead time are met.",
            "Provide seamless onboarding experience for merchants."
          ],
          requirement: [
            "Minimum a Diploma with 1 years of sales experience.",
            "Candidate with experience in IT industry.",
            "Possess strong communication and interpersonal skills.",
            "Team player, self-starter, highly motivated and have a desire to exceed expectations.",
            "Resourceful and sales oriented.",
            "Possess driving license"
          ],
        },
      ],
    };
  },
  computed: {
    active() {
      return this.$route.hash == "#career" ? true : false;
    },
  },
  mounted() {},
  methods: {
    select(key) {
      this.selected = key;
    },
  }
};
</script>