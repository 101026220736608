<template lang="pug">
  .section.left-tab#portfolio
    h3.title(:class="active ? 'active' : ''") Portfolio
</template>

<script>
export default {
  computed: {
    active() {
      return this.$route.hash == '#portfolio' ? true : false;
    },
  },
  mounted() {
  },
};
</script>