<template lang="pug">
  .section.right-tab#contact
    h3.title(:class="active ? 'active' : ''") Contact
    .contact-container
      .contact-tab
        .subtitle 
          i.fas.fa-home 
          | &nbsp;Address
        p.content Colony @ KLCC <br> 1-1 to 1-11 Vipod @ KLCC <br> No. 6, Jalan Kia Peng <br> 50450 Kuala Lumpur
      .contact-tab
        .subtitle 
          i.fab.fa-linkedin 
          | &nbsp;LinkedIn
        a.content(href="https://www.linkedin.com/company/codafuture-sdn-bhd") Click Here
        .subtitle
          i.fas.fa-envelope
          | &nbsp;E-mail
        a.content(href="mailto:admin@codafuture.com") admin@codafuture.com
      .contact-tab
        .subtitle 
          i.fas.fa-phone
          | &nbsp;Telephone
        a.content(href="whatsapp://send?phone=+60126407101&text=Hello%2CCodafuture!") +60 12-640 7101
</template>

<script>
export default {
  computed: {
    active() {
      return this.$route.hash == "#contact" ? true : false;
    },
  },
  mounted() {},
};
</script>
