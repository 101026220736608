<template lang="pug">
  .section.right-tab#project
    h3.title(:class="active ? 'active' : ''") Projects
    p.content New Innovative Softwares that Enhance Your Lifestyle in The Making
    a.subtitle(href="https://kliks.app") Kliks
    .perk-group
      .perk-item
        img.image(src="@/assets/kliks1.png.webp")
      .perk-item
        img.image(src="@/assets/kliks2.png.webp")
      .perk-item
        img.image(src="@/assets/kliks3.png.webp")
    .subtitle Anybook
    .perk-group
      .perk-item
        img.image(src="@/assets/anybook1.png.webp")
      .perk-item
        img.image(src="@/assets/anybook2.png.webp")
</template>

<script>
export default {
  computed: {
    active() {
      return this.$route.hash == '#project' ? true : false;
    },
  },
  mounted() {
  },
};
</script>