<template lang="pug">
  scrollactive.navigation-row(
    v-on:itemchanged="onItemChanged" 
    :offset="offset"
  )
    a.navigation-item.scrollactive-item(href="#about")
      //- i.fas.fa-home
      b Who are we?
    a.navigation-item.scrollactive-item(href="#project")
      //- i.fas.fa-home
      b Projects
    //- a.navigation-item.scrollactive-item(href="#portfolio")
    //-   //- i.fas.fa-home
    //-   b Portfolio
    a.navigation-item.scrollactive-item(href="#career")
      //- i.fas.fa-home
      b Career
    a.navigation-item.scrollactive-item(href="#contact")
      //- i.fas.fa-home
      b Contact
</template>

<script>
export default {
  computed: {
    offset() {
      return window.outerWidth > 1024 ? 160 : 160
    }
  },
  methods: {
    onItemChanged(event, currentItem) {
      // here you have access to everything you need regarding that event
      var hash = currentItem.href.replace(/^.*?(#|$)/, "");

      this.$router.push({ hash: "#" + hash });
    },
  },
};
</script>
