<template lang="pug">
  .home
    .top-bar
      img(alt="Vue logo" src="@/assets/logo.png.webp")
      Nav
    .main
      About
      Project
      //- Portfolio
      Career
      Contact
    .footer
      .footer-bar
        p
        | &copy; 2021 | Codafuture
        i.fa.fa-value(aria-hidden='true') &nbsp;
        //- a(class="d-none" href='https://www.freepik.com/vectors/banner') Banner vector created by starline - www.freepik.com
        //- a(class="d-none" href='https://www.freepik.com/vectors/background') Background vector created by rawpixel.com - www.freepik.com
        //- a(class="d-none" href='https://www.freepik.com/vectors/background') Background vector created by rawpixel.com - www.freepik.com
</template>

<script>
import Nav from "../components/Nav";
import About from "../components/About";
import Project from "../components/Project";
import Portfolio from "../components/Portfolio";
import Career from "../components/Career";
import Contact from "../components/Contact";

export default {
  name: "Home",
  components: {
    Nav,
    About,
    Project,
    Portfolio,
    Career,
    Contact,
  },
};
</script>

<style lang="sass">
@import "../styles/app.sass"
</style>