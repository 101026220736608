<template lang="pug">
  .section.left-tab(id="about")
    h3.title(:class="active ? 'active' : ''") Who are we?
    p.content Technology service provider specializes in website and mobile application built-up, in-house start-up incubation and SaaS services.
    br
    p.content We are a young IT startup, which aims to provide prestigious, best in line solutions to solve your technological problems at a competitive price.
    .subtitle Our Specialties
    .perk-group
      .perk-item
        .icon
          //- i.fas.fa-paint-brush.fa-6x
          img.image-icon(src="@/assets/puzzle.png.webp")
        .label Creative Designs
      .perk-item
        .icon
          //- i.fas.fa-desktop.fa-6x
          img.image-icon(src="@/assets/web.png.webp")
        .label Intuitive & Optimized Website
      .perk-item
        .icon
          //- i.fas.fa-mobile.fa-6x
          img.image-icon(src="@/assets/tablet.png.webp")
        .label Innovative Mobile Application
    .perk-group
      .perk-item
        .icon
          //- i.fas.fa-code.fa-6x
          img.image-icon(src="@/assets/web-programming.png.webp")
        .label Seamless Backend Integration
      .perk-item
        .icon 
          //- i.fas.fa-cloud.fa-6x
          img.image-icon(src="@/assets/computing-cloud.png.webp")
        .label Latest Cloud Technologies
      .perk-item
        .icon
          //- i.fas.fa-lock.fa-6x\
          img.image-icon(src="@/assets/secure.png.webp")
        .label Secure & Safe
</template>

<script>
export default {
  computed: {
    active() {
      return this.$route.hash == "#about" ? true : false;
    },
  },
  mounted() {},
};
</script>
